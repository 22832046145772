import React from 'react';
import OfferSliderRightArrow from 'images/slider-right-arrow.svg';

const OffersRightArrow = (props) => (
  <img
    role="presentation"
    className={props.className}
    onClick={props.onClick}
    src={OfferSliderRightArrow}
    alt="Right slider arrow"
  />
);

export default OffersRightArrow;
