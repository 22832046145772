import React from 'react';

import OfferSliderLeftArrow from 'images/slider-left-arrow.svg';

const OffersLeftArrow = (props) => (
  <img
    role="presentation"
    className={props.className}
    onClick={props.onClick}
    src={OfferSliderLeftArrow}
    alt="Left slider arrow"
  />
);

export default OffersLeftArrow;
